import _ from 'lodash';
import React from 'react';
import moment from "moment";
import { Badge } from 'reactstrap';
import {
    US,
    GB,
    IE,
    DE,
    NL,
    BE,
    FR,
    BG,
    CA,
    МТ,
    IN,
    NO_QUESTION_PRODUCT,
    PIZZA_PRODUCT,
    GENERAL_PRODUCT,
    WEB, WEB_IOS,
    WEB_ANDROID, APP_IOS, APP_ANDROID,
    PAUSED,
    PRE_ORDER,
    ORDER,
    ARABIC,
    ENGLISH,
    BULGARIAN,
    ITALIAN,
    PORTUGUESE,
    FRENCH,
    AU,
    BR,
    PT,
    IT,
    ES,
    PL,
    MX,
    AR,
    CL,
    DEFAULT_SERVICEFEE,
    SERVICE_FEES_IN_PERCENT_ARRAY,
} from './Constants';
import i18n from "../i18next";
import { isTablet, isMobile, isBrowser, isIOS, isAndroid } from 'mobile-device-detect';
import { saveWorkingRestCount } from '../actions/index';
import CryptoJS from 'crypto-js';

export const getRestaurantStatus = (restaurant) => {
    if (restaurant.workingDayIndex === 2) {
        return false;
    }

    if (restaurant.workingDay !== null) {
        const restaurantWorkingDate = restaurant.workingDay.date;
        const restaurantTimeOffset = moment(restaurant.restaurantTime, 'DD-MM-YYYY HH:mm:ss').diff(moment());
        const currentDate = restaurant.restaurantTime.split(' ')[0];

        const currentHours = moment().add(restaurantTimeOffset, 'milliseconds').format('HH');
        const currentMinutes = moment().add(restaurantTimeOffset, 'milliseconds').format('mm');

        const restaurantOpeningHours = restaurant.workingDay.openingHour.openHour;
        const restaurantClosingHours = restaurant.workingDay.openingHour.closeHour;

        const restaurantOpeningMinutes = restaurant.workingDay.openingHour.openMinute;
        const restaurantClosingMinutes = restaurant.workingDay.openingHour.closeMinute;

        const currentTime = moment(`${currentHours}:${currentMinutes}`, 'HH:mm');

        const openTimeValue = `${restaurantOpeningHours}:${restaurantOpeningMinutes}`;
        const openTime = moment(openTimeValue, 'HH:mm');
        const closeTimeValue = `${restaurantClosingHours}:${restaurantClosingMinutes}`;
        const closeTime = moment(closeTimeValue, 'HH:mm');

        if (moment(restaurantWorkingDate, 'DD-MM-YYYY').isAfter(moment(currentDate, 'DD-MM-YYYY'))) {
            return false;

        } else if (moment(restaurantWorkingDate, 'DD-MM-YYYY').isSame(moment(currentDate, 'DD-MM-YYYY'))) {

            if (currentTime.isBetween(openTime, closeTime, openTimeValue, closeTimeValue)) {
                return true;
            } else if (currentTime.isAfter(openTime) && currentTime.isAfter(closeTime)) {
                return true;
            } else {
                return false;
            }

        } else if (moment(currentDate, 'DD-MM-YYYY').isAfter(moment(restaurantWorkingDate, 'DD-MM-YYYY'))) {

            if (currentTime.isBetween(closeTime, openTime)) {
                return false;
            } else {
                return true;
            }

        } else {

            return false;
        }
    } else {
        return false;
    }
}

export const getRestaurantStatusBadge = (restaurantBadge) => {

    switch (restaurantBadge) {
        case PAUSED:
            return <Badge color="danger">{i18n.t('screens:restaurantSelectionScreen.paused')}</Badge>;
        case PRE_ORDER:
            return <Badge color="success">{i18n.t('screens:restaurantSelectionScreen.preOrder')}</Badge>;
        case ORDER:
            return <Badge color="success">{i18n.t('screens:restaurantSelectionScreen.order')}</Badge>
    }
};

export const getRestaurantStylesBasedOnStatusBadge = (restaurantBadge, colors) => {
    let locationIconColor, liClassName;
    let closedRestaurant = {};

    if (restaurantBadge.props.children === i18n.t('screens:restaurantSelectionScreen.order')) {

        closedRestaurant.pointerEvents = 'auto';
        closedRestaurant.border = `1px solid ${colors.primaryColor}`;
        locationIconColor = colors.primaryColor;

        liClassName = 'restaurantsSelectionScreenList';
    }

    if (restaurantBadge.props.children === i18n.t('screens:restaurantSelectionScreen.preOrder')) {

        closedRestaurant.pointerEvents = 'auto';
        closedRestaurant.border = `1px solid ${colors.primaryColor}`;
        locationIconColor = colors.primaryColor;

        liClassName = 'restaurantsSelectionScreenList';
    }

    if (restaurantBadge.props.children === i18n.t('screens:restaurantSelectionScreen.paused')) {

        closedRestaurant.pointerEvents = 'none';
        closedRestaurant.border = `1px solid ${colors.inactiveColor}`;
        locationIconColor = colors.inactiveColor;

        liClassName = 'restaurantsSelectionScreenList inactive offline';
    }

    return {
        locationIconColor: locationIconColor,
        liClassName: liClassName,
        closedRestaurant: closedRestaurant
    };
};

export const formatGeoLocatedCountryToCountryCode = (country) => {
    switch (country) {
        case "United States":
            return US;
        case "United Kingdom":
            return GB;
        case "Ireland":
            return IE;
        case "Germany":
            return DE;
        case "Netherlands":
            return NL;
        case "Belgium":
            return BE;
        case "France":
            return FR;
        case "Bulgaria":
            return BG;
        case "Canada":
            return CA;
        case "Malta":
            return МТ;
        case "India":
            return IN;
        case "Australia":
            return AU;
        case "Brazil":
            return BR;
        case "Portugal":
            return PT;
        case "Italy":
            return IT;
        case "Spain":
            return ES;
        case "Poland":
            return PL
        case "Mexico":
            return MX
        case "Argentina":
            return AR
        case "Chile":
            return CL
        default:
            return country;
    }
}

export const formatCountryCodeToCountry = (code) => {
    switch (code) {
        case "US":
            return "United States";
        case "GB":
            return "United Kingdom";
        case "IE":
            return "Ireland";
        case "DE":
            return "Germany";
        case "NL":
            return "Netherlands";
        case "NL":
            return "Belgium";
        case "FR":
            return "France";
        case "BG":
            return "Bulgaria";
        case "CA":
            return "Canada";
        case "IN":
            return "India";
        case "MX":
            return "Mexico"
        case "AR":
            return "Argentina"
        case "CL":
            return "Chile"
        default:
            return "IE";
    }
};

export const transformProductPrice = (price) => {
    let tranformedPrice = 0;
    if (price !== undefined && price !== null && typeof price == 'number' && price !== 0) {
        tranformedPrice = Number(Math.round(price + 'e2') + 'e-2');
        return tranformedPrice.toFixed(2);
    }

    return tranformedPrice.toFixed(2);
}

export const transformProductsComingFromServer = (products, applyPromotion, orderHistory) => {
    let productsWithTransformedIds = [];
    let allProducts = [];
    let updatedProduct;

    products.map(product => {
        updatedProduct = {
            product: {
                id: applyPromotion === true ? product.product.id : product.productId,
            },
            instruction: product.instruction,
            mealDeal: product.mealDeal,
            mealDealProduct: product.mealDealProduct,
            name: product.name,
            orderQuestions: product.orderQuestions,
            parentProductId: applyPromotion === true ? null : product.parentProductId,
            price: product.price,
            productId: applyPromotion === true ? product.product.id : product.productId,
            promotionFreeProduct: product.promotionFreeProduct,
            quantity: product.quantity,
            sizeName: product.sizeName,
            tax: applyPromotion === true ? product.tax : product.tax,
            thumbnailPath: product.thumbnailPath,
            totalPrice: applyPromotion === true ? null : product.totalPrice,
            description: product.description ? product.description : null,
            basicPrice: product.basicPrice ? product.basicPrice : null
        }

        // if(orderHistory == true) {
        //     updatedProduct['orderHistoryProduct'] = true;
        // }

        productsWithTransformedIds.push(updatedProduct)
    });

    for (let i = 0; i < productsWithTransformedIds.length; i++) {             // Iterating all incoming products
        const mealDealProductsArray = new Array();                            // Create new array for the Meal Deals 
        let product = productsWithTransformedIds[i];

        if (product.mealDeal == true) {                                   // Deals & deal products
            mealDealProductsArray.push(product);                          // push product /the deal is always first
            for (let k = (i + 1); k < productsWithTransformedIds.length; k++) {             // Iterating the products again but without the one already in the array
                let mealDealProduct = productsWithTransformedIds[k];
                if (mealDealProduct.mealDealProduct) {                    // Push all Meal Deal Products until it reaches the array end...
                    mealDealProductsArray.push(mealDealProduct);
                    if (k == productsWithTransformedIds.length - 1) {
                        allProducts.push(mealDealProductsArray);
                        return allProducts;
                    }
                } else {                                                  // ...or new Meal Deal.
                    i = k - 1;                                            // Then sets the iterator (i) to the next un-added item
                    allProducts.push(mealDealProductsArray);
                    break;
                }
            }
        } else {
            allProducts.push(product);
        }
    }
    return allProducts;
}

export const transformProducts = (shoppingCartProducts) => {
    const orderedProducts = [];
    shoppingCartProducts.map((product) => {

        let singleProduct = {};
        singleProduct['productId'] = Number(product.product.id);

        if (product.orderQuestions.length > 0) {
            const availableOrderAnswers = [];

            product.orderQuestions.map(question => {

                if (question.orderAnswers.length > 0) {

                    question.orderAnswers.map(answer => {
                        let answerObj = {
                            answerId: answer.answer.id
                        };
                        availableOrderAnswers.push(answerObj);
                    });

                    return singleProduct['availableOrderAnswers'] = _.uniqBy(availableOrderAnswers, 'answerId');
                } else {
                    return singleProduct['availableOrderAnswers'] = null;
                }
            });
        } else {
            singleProduct['availableOrderAnswers'] = null;
        }

        orderedProducts.push(singleProduct);
    });


    return orderedProducts;
}

export const getGoogleAddressParts = (results) => {
    // streetNumber search queries
    let roomSearchQuery = { query: 'room', position: 1, result: getGoogleAddressPart(results, 'room') };
    let floorSearchQuery = { query: 'floor', position: 2, result: getGoogleAddressPart(results, 'floor') };
    let streetNumberSearchQuery = { query: 'street_number', position: 3, result: getGoogleAddressPart(results, 'street_number') };
    let subpremiseSearchQuery = { query: 'subpremise', position: 4, result: getGoogleAddressPart(results, 'subpremise') };
    let premiseSearchQuery = { query: 'premise', position: 5, result: getGoogleAddressPart(results, 'premise') };

    let streetNumberResults = [];
    streetNumberResults.push(roomSearchQuery, floorSearchQuery, streetNumberSearchQuery, premiseSearchQuery, subpremiseSearchQuery);

    // streetName search queries
    let neighborhoodSearchQuery = { query: 'neighborhood', position: 2, result: getGoogleAddressPart(results, 'neighborhood') };
    let routeSearchQuery = { query: 'route', position: 1, result: getGoogleAddressPart(results, 'route') };
    let intersectionSearchQuery = { query: 'intersection', position: 3, result: getGoogleAddressPart(results, 'intersection') };
    let establishmentSearchQuery = { query: 'establishment', position: 4, result: getGoogleAddressPart(results, 'establishment') };
    let pointOfInterestSearchQuery = { query: 'point_of_interest', position: 5, result: getGoogleAddressPart(results, 'point_of_interest') };

    let streetNameResults = [];
    streetNameResults.push(routeSearchQuery, intersectionSearchQuery, establishmentSearchQuery, pointOfInterestSearchQuery, neighborhoodSearchQuery);

    // country search queries
    let countrySearchQuerie = 'country';

    // city search queries
    let sublocalitySearchQuery = { query: 'sublocality', position: 1, result: getGoogleAddressPart(results, 'sublocality') };
    let citySearchQuery = { query: 'locality', position: 2, result: getGoogleAddressPart(results, 'locality') };

    if (!citySearchQuery.result) {
        citySearchQuery = { query: 'postal_town', position: 2, result: getGoogleAddressPart(results, 'postal_town') };
    }

    let cityNameResults = [];
    cityNameResults.push(citySearchQuery, sublocalitySearchQuery);

    // zipCode search queries
    let postalCodeSearchQuery = { query: 'postal_code', position: 1, result: getGoogleAddressPart(results, 'postal_code') };
    let postalTownSearchQuery = { query: 'postal_town', position: 2, result: getGoogleAddressPart(results, 'postal_town') };

    let postalCodeResults = [];
    postalCodeResults.push(postalCodeSearchQuery, postalTownSearchQuery);

    //placeId search queries
    let placeIdQuery = 'street_address';

    let addressParts = {
        streetNumber: generateAddressPart(streetNumberResults),
        streetName: generateAddressPart(streetNameResults),
        city: generateAddressPart(cityNameResults),
        country: getGoogleAddressPart(results, countrySearchQuerie),
        zipCode: generateAddressPart(postalCodeResults),
        placeId: getPlaceId(results, placeIdQuery)
    };

    return addressParts;
}

const generateAddressPart = (addressParts) => {
    let generatedAddress = '';

    // Filter undefined address parts and sort the existing parts based on position argument in the object
    let sortedExistingAddressParts = addressParts.filter(addressPart => addressPart.result !== undefined)
        .sort((currentAddress, nextAddress) => { return currentAddress.position - nextAddress.position });

    // Generate the address part from all existingAddressParts
    sortedExistingAddressParts.map((addressPart, index) => {
        const lastAddressPart = index === sortedExistingAddressParts.length - 1;

        if (!lastAddressPart) {
            generatedAddress += addressPart.result + ', ';
        } else {
            generatedAddress += addressPart.result;
        }
    });

    return generatedAddress;
}

const getGoogleAddressPart = (address_components, searchQuery) => {
    // uses only address_components but checks 
    // then checks the types with preconfigured values

    // for (let i = 0; i < results.length; i++) {
    // let address_components = results[i].address_components;

    for (let j = 0; j < address_components.length; j++) {
        let types = address_components[j].types;

        for (let k = 0; k < types.length; k++) {
            if (types[k] == searchQuery) { return address_components[j].long_name }
        }
    }
    // }

}

const getPlaceId = (results, searchQuery) => {
    for (let i = 0; i < results.length; i++) {
        let address_components = results[i];

        for (let j = 0; j < address_components.types.length; j++) {
            if (address_components.types[j] === searchQuery) {
                return address_components.place_id;
            }
        }
    }
}

export const dateAndTimeToUserFriendlyFormat = (estimateOrderTime, restaurant, isArabic) => {
    let orderTime = '';
    if (estimateOrderTime.time === null) {
        // orderTime = 'ASAP ' + estimateOrderTime.estimateOrderTime + ' minutes';
        let currentTime = moment().add(restaurant.restaurantTimeOffset, 'milliseconds');
        currentTime.add(estimateOrderTime.estimateOrderTime, 'minutes');
        orderTime = isArabic ? `${currentTime.add(estimateOrderTime.cookTime, 'minutes').format('HH:mm')} :${i18n.t('screens:restaurantDetailsScreen.asap')}` : `${i18n.t('screens:restaurantDetailsScreen.asap')}: ${currentTime.add(estimateOrderTime.cookTime, 'minutes').format('HH:mm')}`;
    } else if (estimateOrderTime.time !== null) {

        let currentDate = moment().add(restaurant.restaurantTimeOffset, 'milliseconds');
        let orderTimeDate = estimateOrderTime.wantedTime.slice(0, 10);
        let orderDate = moment(orderTimeDate, 'DD-MM-YYYY');
        let notSameDay = moment(orderDate, 'DD-MM-YYYY').isAfter(currentDate, 'day');
        // const sameDay = moment(orderDate,'DD-MM-YYYY').isSame(currentDate, 'day');

        let earliestReadyTime = moment().add(restaurant.restaurantTimeOffset, 'milliseconds').add(estimateOrderTime.estimateOrderTime, 'minutes').add(estimateOrderTime.cookTime, 'minutes');
        let currentOrderTime = moment(estimateOrderTime.wantedTime, 'DD-MM-YYYY HH-mm-ss');

        let orderHour = parseInt(estimateOrderTime.time.slice(0, 2));
        if (orderHour >= 0 && orderHour <= 6) {
            currentOrderTime.add(1, 'day');
        }

        if (notSameDay) {
            orderTime = isArabic ? `${estimateOrderTime.time} ${i18n.t('screens:restaurantDetailsScreen.for')} ${orderDate.format('D MMM')} ${i18n.t('screens:restaurantDetailsScreen.scheduledFor')} ` : `${i18n.t('screens:restaurantDetailsScreen.scheduledFor')} ${orderDate.format('D MMM')} ${i18n.t('screens:restaurantDetailsScreen.for')} ${estimateOrderTime.time} `;
        } else {
            if (earliestReadyTime.isAfter(currentOrderTime)) {
                orderTime = isArabic ? `${earliestReadyTime.format('HH:mm')} ${i18n.t('screens:restaurantDetailsScreen.scheduledForToday')}` : `${i18n.t('screens:restaurantDetailsScreen.scheduledForToday')} ${earliestReadyTime.format('HH:mm')}`;
            } else {
                orderTime = isArabic ? `${estimateOrderTime.time} ${i18n.t('screens:restaurantDetailsScreen.scheduledForToday')}` : `${i18n.t('screens:restaurantDetailsScreen.scheduledForToday')} ${estimateOrderTime.time}`;
            }
        }
    }

    return orderTime;
};

export const isRestaurantAboutToClose = (estimateOrderTime, restaurant) => {

    if (!estimateOrderTime.futureOrder) {
        return checkRestaurantClosingTime(estimateOrderTime, restaurant);
    } else {
        return checkClosingTimeFutureOrder(estimateOrderTime, restaurant);
    }
};

const checkClosingTimeFutureOrder = (estimateOrderTime, restaurant) => {

    let currentDate = moment().add(restaurant.restaurantTimeOffset, 'milliseconds');
    if (currentDate.get('hour') >= 0 && currentDate.get('hour') < 6) {
        currentDate.add(-1, 'hour');
    }
    let compareDate;
    if (estimateOrderTime.timeSlotBased) {
        compareDate = moment(estimateOrderTime.time.date, 'DD-MM-YYYY');
    } else {
        compareDate = moment(estimateOrderTime.wantedTime.slice(0, 10), 'DD-MM-YYYY');
    }
    if (compareDate.format('DD-MM-YYYY') === currentDate.format('DD-MM-YYYY')) {
        return checkRestaurantClosingTime(estimateOrderTime, restaurant);
    } else {
        return {
            isRestaurantClosed: false,
            isRestaurantAboutToClose: false,
            remainingTime: null
        }
    }
};

const checkRestaurantClosingTime = (estimateOrderTime, restaurant) => {
    let returnObject = {};
    let currentDateTime = moment(moment().add(restaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY HH-mm'), 'DD-MM-YYYY HH-mm');

    let acceptOrdersTill;

    if (estimateOrderTime.futureOrder) {
        acceptOrdersTill = moment(moment().add(restaurant.restaurantTimeOffset, 'milliseconds').format('DD-MM-YYYY HH-mm'), 'DD-MM-YYYY HH-mm');
    } else {
        acceptOrdersTill = moment(restaurant.workingDay.date, 'DD-MM-YYYY');
    }

    if (restaurant.workingDay.openingHour.closeHour >= 0
        && restaurant.workingDay.openingHour.closeHour < 8
        && (!estimateOrderTime.futureOrder
            || (estimateOrderTime.futureOrder
                && !(currentDateTime.hour() >= 0
                    && currentDateTime.hour() < 8)))) {
        acceptOrdersTill.add(1, 'day');
    }

    if (!estimateOrderTime.timeSlotBased) {
        acceptOrdersTill.hour(restaurant.workingDay.openingHour.closeHour);
        acceptOrdersTill.minute(restaurant.workingDay.openingHour.closeMinute);
    } else {
        acceptOrdersTill.hour(estimateOrderTime.time.fromHour);
        acceptOrdersTill.minute(estimateOrderTime.time.fromMinute);
    }

    acceptOrdersTill.add(-estimateOrderTime.estimateOrderTime, "minutes");

    let timeDifference = moment.duration(acceptOrdersTill.diff(currentDateTime));

    let timeDifferenceMinutes = Math.round(timeDifference.asMinutes());

    if (timeDifferenceMinutes > 15) {
        returnObject = {
            isRestaurantClosed: false,
            isRestaurantAboutToClose: false,
            remainingTime: null
        }
    } else if (timeDifferenceMinutes >= 0 && timeDifferenceMinutes <= 15) {
        returnObject = {
            isRestaurantClosed: false,
            isRestaurantAboutToClose: true,
            remainingTime: timeDifferenceMinutes
        }
    } else if (timeDifferenceMinutes < 0) {
        returnObject = {
            isRestaurantClosed: true,
            isRestaurantAboutToClose: false,
            remainingTime: null
        }
    } else {
        returnObject = {
            isRestaurantClosed: false,
            isRestaurantAboutToClose: false,
            remainingTime: null
        }
    }
    return returnObject;
};

export const getAllUrlParams = (url) => {

    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    let queryParams = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        let arr = queryString.split('&');

        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            let a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            let paramName = a[0];
            let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // (optional) keep case consistent
            // paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue;

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                let key = paramName.replace(/\[(\d+)?\]/, '');
                if (!queryParams[key]) queryParams[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    let index = /\[(\d+)\]/.exec(paramName)[1];
                    queryParams[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    queryParams[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!queryParams[paramName]) {
                    // if it doesn't exist, create property
                    queryParams[paramName] = paramValue;
                } else if (queryParams[paramName] && typeof queryParams[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    queryParams[paramName] = [queryParams[paramName]];
                    queryParams[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    queryParams[paramName].push(paramValue);
                }
            }
        }
    }

    return queryParams;
}

export const convertStringToBoolean = (value) => {
    if (value == 'true') {
        return true;
    } else if (value == 'false') {
        return false;
    } else {
        return value;
    }
}

export const transformExpirationDate = (date) => {

    if (date.length == 6) {
        const mouth = 0 + date.slice(0, 1);
        const year = date.slice(4, 6);

        return mouth + '/' + year
    } else if (date.length > 6) {
        const mouth = date.slice(0, 2);
        const year = date.slice(5, 7);

        return mouth + '/' + year
    }
}

export const isCardAlreadyExpired = (card) => {
    let cardDate = moment(card.expirationDate, 'MM:YYYY');
    let currentDate = moment().format('MM:YYYY');

    if (moment(cardDate).isBefore(currentDate, 'month')) {
        return true;
    } else {
        return false;
    }
}

export const checkIfMenuIsAvailable = (menus, selectedMenuId) => {
    let isMenuAvailable = false;

    menus.map(menu => {
        if (menu.id == selectedMenuId) {
            isMenuAvailable = menu.availableMenu;
        }
    });

    return isMenuAvailable;
}

export const checkIfCategoryIsAvailable = (categories, products, selectedProduct) => {
    let selectedCategoryId = -1;
    let isCategoryAvailable = false;

    products.map(product => {
        if (product.id == selectedProduct.id) {
            selectedCategoryId = product.categoryId;

            categories.map(category => {
                if (category.id == selectedCategoryId) {
                    isCategoryAvailable = category.availableCategory;
                }
            });
        }

        // Check other product sizes ids for a matching one
        product.productSizesIds.map((productSizeId) => {
            if (productSizeId === selectedProduct.id) {
                selectedCategoryId = product.categoryId;

                categories.map(category => {
                    if (category.id == selectedCategoryId) {
                        isCategoryAvailable = category.availableCategory;
                    }
                });
            }
        });
    });

    return isCategoryAvailable;
};

export const checkIfProductIsAvailable = (products, selectedProduct) => {
    let isProductAvailable = false;

    products.map(product => {
        if (product.id === selectedProduct.id) {
            isProductAvailable = product.availableProduct;
        }

        // Check other product sizes ids for a matching one
        product.productSizesIds.map((productSizeId) => {
            if (productSizeId === selectedProduct.id) isProductAvailable = product.availableProduct;
        });
    });

    return isProductAvailable;
};

export const calculateDiscount = (order) => {

    let tip = order.tip ? order.tip : 0;
    let vat = order.totalTax ? order.totalTax : 0;

    let deliveryFee = order.deliveryCharge ? order.deliveryCharge : 0;
    let priceBeforeDiscount = order.priceBeforeDiscount;

    const totalPrice = order.total;
    let discount = totalPrice - Number((priceBeforeDiscount + vat + tip + deliveryFee + order.orderDeposit).toFixed(2));

    return discount;
}

export const getAllergensAndDietaryInfo = (allergens, dietaryInfo) => {
    let allergenList = '* ';
    let dietaryList = '';

    allergens.map((allergenObject, index) => {
        let allergenName = i18n.t(`common:allergens.${allergenObject.value}`);

        if (index !== allergens.length - 1) { // IF NOT the last allergen
            allergenList += allergenName + ', ';
        } else {
            allergenList += allergenName;
        }
    });

    dietaryInfo.map((dietaryObject) => {
        let dietaryInfo = i18n.t(`common:dietaryInfo.${dietaryObject.value}`);
        dietaryList += ', ' + dietaryInfo;
    });

    if (allergenList === '* ' && dietaryList !== ', ') {
        dietaryList = '* ';
        dietaryInfo.map((dietaryObject, index) => {
            let dietaryInfo = i18n.t(`common:dietaryInfo.${dietaryObject.value}`);
            if (index === 0) {
                dietaryList += dietaryInfo;
            } else {
                dietaryList += ', ' + dietaryInfo;
            }
        });

        return dietaryList;
    }

    if (allergenList === '* ' && dietaryList === ', ') {
        return '';
    }

    return allergenList + dietaryList;
};

export const getLoggedInUserFirstName = (userName) => {

    if (userName !== undefined && userName !== null) {
        let firstName = userName.split(' ')[0];

        return firstName;
    }

    return '';
};

export const getCurrencySymbol = (currency) => {
    if (currency) {
        switch (currency) {
            case 'eur': // Euro
                return '€';
            case 'gbp': // Great Britain Pound
                return '£';
            case 'usd': // USA Dollar
                return '$';
            case 'cad': // Canada Dollar
                return '$';
            case 'bgn': // Bulgaria Lev
                return 'лв';
            case 'aud': // Australian Dollar
                return '$';
            case 'inr': // Indian Rupee
                return '₹';
            case 'mxn': // Mexican Peso
                return '$'
            case 'ars': // Argentine Peso
                return '$'
            case 'clp': // Chilean Peso
                return '$'
            default:
                return '€';
        }
    }

    return '€';
}

export const getPriceWithCurrencySymbol = (price, currency, isArabic) => {
    if (currency && isArabic) {
        switch (currency) {
            case 'eur': // Euro
                return price + '€';
                break;
            case 'gbp': // Great Britain Pound
                return price + '£';
                break;
            case 'usd': // USA Dollar
                return price + '$';
                break;
            case 'cad': // Canada Dollar
                return price + '$';
                break;
            case 'bgn': // Bulgaria Lev
                return 'лв' + price;
                break;
            case 'mxn': // Mexican Peso
                return price + '$';
                break;
            case 'ars': // Argentine Peso
                return price + '$';
                break;
            case 'clp': // Chilean Peso
                return price + '$';
                break;
            default:
                return price + '€';
        }
    } else if (currency) {
        switch (currency) {
            case 'eur': // Euro
                return '€' + price;
            case 'gbp': // Great Britain Pound
                return '£' + price;
            case 'usd': // USA Dollar
                return '$' + price;
            case 'cad': // Canada Dollar
                return '$' + price;
            case 'bgn': // Bulgaria Lev
                return price + 'лв';
            case 'aud': // Australian Dollar
                return '$' + price;
            case 'inr': // Indian Rupee
                return '₹' + price;
            case 'mxn': // Mexican Peso
                return '$' + price;
            case 'ars': // Argentine Peso
                return '$' + price;
            case 'clp': // Chilean Peso
                return '$' + price;
            default:
                return '€' + price;
        }
    }

    return '€' + price;
}

export const getQueryInfoFromUrl = () => {

    const urlParams = new URLSearchParams(window.location.search);

    return {
        brandId: parseInt(urlParams.get('brandId')),
        height: parseInt(urlParams.get('height')),
        width: parseInt(urlParams.get('width')),
        navigationType: parseInt(urlParams.get('navigationType')),
        customerThemeType: urlParams.get('customerThemeType')
    };
}

export const determineProductType = (product) => {
    let productType = null;

    if (product.questions.length === 0) {
        productType = NO_QUESTION_PRODUCT;

    } else if (product.questions.length > 0) {
        let ingredientQuestionFound = false;

        product.questions.map((question) => {
            const hasIngredientQuestion = question.posDefault;

            if (hasIngredientQuestion) {
                ingredientQuestionFound = true;
            }
        });

        (ingredientQuestionFound) ? productType = PIZZA_PRODUCT : productType = GENERAL_PRODUCT;
    }

    return productType;
};

//PWA Common functions
export const renderFirstCategoryHiddenId = (menus, category, isMobile, i, isPWA) => {

    if (isMobile) {
        let inputHeight = 40;
        if (menus.length > 1) {
            inputHeight = 100;
        }
        if (isPWA) {
            inputHeight -= 40;

            if (menus.length > 1) {
                inputHeight = isTablet ? 100 : 60;
            }
        }
        return (
            <input type="text" value={category.name} id={category.name + i}
                style={{ height: inputHeight, visibility: "hidden" }} className="categoryNameMobile" />
        )
    } else {
        return (
            <input type="text" value={category.name}
                style={{ height: 50, visibility: "hidden" }} className="categoryName" />
        )
    }
}

// Set last opened page before drawer profile screens opened
export const setLastOpenedPagePWA = (openChangeOrderTimeScreen) => {
    let urlHash = window.location.hash.split('');
    urlHash.splice(0, 1);
    const path = urlHash.join('');
    // dispatches route to store
    return openChangeOrderTimeScreen(path);
}

export const handleIosElasticScroll = (actionType) => {
    let headerContainerElement = document.getElementById('headerContainer');

    if (headerContainerElement && actionType === 'Disable Scroll') {
        headerContainerElement.classList.add('disableScroll');
    } else if (headerContainerElement && actionType === 'Disable Scroll') {
        headerContainerElement.classList.remove('disableScroll');
    }
};

export const preventBodyScrollOnSafari = (opened, prevScrollPostion) => {
    const docBody = document.getElementById('bootstrap-overrides');
    // const docBody = document.getElementById('root');

    if (opened) {
        // Setting the body position to fixed so that the body is
        // NOT scrollable under the modal window on mobile Safari 
        docBody.style.cssText = "position: fixed;";
    } else {
        // Resetting the body position to its initial value so that
        // the body IS scrollable again
        docBody.style.cssText = "position: static;";

        if (prevScrollPostion || prevScrollPostion === 0) {
            docBody.scrollTop = prevScrollPostion;
            docBody.style.top = prevScrollPostion + 'px';
            window.scrollTo(0, prevScrollPostion);
        }
    }
}

export const setOpenedRestCount = (restaurants) => {
    let workingRestCount = restaurants.length;

    restaurants.map(restaurant => {
        const isRestaurantOpenStatus = getRestaurantStatus(restaurant);

        if (restaurant.restaurantPaused) {
            workingRestCount--;
        } else if (!restaurant.restaurantPaused && (!restaurant.posOnline && restaurant.futureOrdersDisabled)) {
            workingRestCount--;
        } else if (!restaurant.restaurantPaused && (restaurant.futureOrdersDisabled && !isRestaurantOpenStatus)) {
            workingRestCount--;
        }
    });

    return saveWorkingRestCount(workingRestCount);
};

export const getQueryParams = () => {
    let themeType = new URLSearchParams(window.location.search).get('theme');
    let color = new URLSearchParams(window.location.search).get('primaryColor');
    let restaurantId = new URLSearchParams(window.location.search).get('restaurantId');
    let typeId = new URLSearchParams(window.location.search).get('typeId');

    let restaurantIdParam = `?restaurantId=${restaurantId}`;
    let themeTypeParam = `&theme=${themeType}`;
    let colorParam = color ? `&primaryColor=${color}` : '';
    let typeIdParam = `&typeId=${typeId}`;

    return `${restaurantIdParam}${themeTypeParam}${colorParam}${typeIdParam}`;
}

export const pixelTaxRaunding = (taxes) => {
    return taxes.map(tax => {
        tax.total = Number((Number(Math.round(tax.total + 'e2') + 'e-2')).toFixed(2));
        return tax;
    })
}

export const nutriValueTotal = (nutritions) => {
    let total = 0;
    nutritions.map(nutri => {
        if (nutri.nutrition.nutritionCode === 'cal') {
            total += nutri.value;
        }
    });

    return total;
}

export const makeCookTimeCalculations = (additionalMoneyCookTimes, shoppingCartProducts, subtotal) => {

    let generalLongestCookTime = 0;
    let productLongestCookTime = 0;

    if (additionalMoneyCookTimes) {
        let additionalMoneyCookTimesCopy = [...additionalMoneyCookTimes];
        additionalMoneyCookTimesCopy
            .sort((a, b) => (a.money < b.money) ? 1 : -1)
            .every(moneyCookTime => {
                if (subtotal > moneyCookTime.money) {
                    generalLongestCookTime = moneyCookTime.minutes;
                    return false
                }
                return true
            })
    }

    if (shoppingCartProducts) {
        shoppingCartProducts
            .forEach(product => {
                if (product.mealDeal) {
                    product.questions.forEach(question => {
                        if (question.selectedProduct.cookTime > productLongestCookTime) {
                            productLongestCookTime = question.selectedProduct.cookTime
                        }
                    })
                } else {
                    if (product.cookTime > productLongestCookTime) {
                        productLongestCookTime = product.cookTime
                    }
                }
            })
    }

    return generalLongestCookTime > productLongestCookTime ? generalLongestCookTime : productLongestCookTime;
}

export const mealDealCookTimeCalculations = (mealDealProduct) => {
    let productLongestCookTime = 0;

    mealDealProduct.questions.forEach(question => {
        if (question.selectedProduct) {
            if (question.selectedProduct.cookTime > productLongestCookTime) {
                productLongestCookTime = question.selectedProduct.cookTime
            }
        }
    })
    return productLongestCookTime;
}

export const caclculateProductTax = (product) => {
    let productTax = 0;
    let prodcutSecondaryTax = 0;

    if (product.tax) {
        productTax = (product.mealDeal ? product.mealDealProduct ? product.originalProductPrice : product.price : product.price) * product.tax.taxPercent / 100;
        productTax = Number((Number(Math.round(productTax + 'e2') + 'e-2')).toFixed(2));
    }

    if (product.secondaryTax) {
        prodcutSecondaryTax = (product.mealDeal ? product.mealDealProduct ? product.originalProductPrice : product.price : product.price) * product.secondaryTax.taxPercent / 100;
        prodcutSecondaryTax = Number((Number(Math.round(prodcutSecondaryTax + 'e2') + 'e-2')).toFixed(2));
    }

    return productTax + prodcutSecondaryTax;
}

export const getOrderSource = (pwaAppRunning) => {
    let source = "";

    if (isBrowser) {
        source = WEB;
    } else if (isMobile && isIOS && !pwaAppRunning) {
        source = WEB_IOS;
    } else if (isMobile && isAndroid && !pwaAppRunning) {
        source = WEB_ANDROID;
    } else if (isIOS && pwaAppRunning) {
        source = APP_IOS;
    } else if (isAndroid && pwaAppRunning) {
        source = APP_ANDROID;
    }

    return source;
}

export const correctRounding = (sum, decimalNumber) => {
    return +(Number(Math.round(sum + 'e2') + 'e-2').toFixed(decimalNumber));
}

export const negativeDecimalsValidation = (e) => {
    let inputKeyCode = e.keyCode ? e.keyCode : e.which;

    if (inputKeyCode != null) {
        if (inputKeyCode == 43 || inputKeyCode == 45 || inputKeyCode == 69
            || inputKeyCode == 101) e.preventDefault();
    }
}
//
export const isArrayUndefinedOrNull = (array) => {
    if (array !== undefined && array !== null && array.length > 0) {
        return true;
    }
    return false;
}
export const isItemUndefinedOrNull = (item) => {
    if (item !== undefined && item !== null) {
        return true;
    }
    return false;
}

export const isCardPaymetnAvailable = (availablePaymentTypes) => {
    let isAvailable = false;
    availablePaymentTypes.map((payment, i) => {
        if (payment.id == 1) {
            isAvailable = true;
        }
    });
    return isAvailable;
}

export const changeRTL = (language) => {
    if (language === ARABIC) {
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    } else {
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    }
}

export const formatLanguageCode = (language) => {
    switch (language) {
        case ARABIC:
            return 'ARABIC';
        case ENGLISH:
            return 'ENGLISH';
        case BULGARIAN:
            return 'BULGARIAN';
        case ITALIAN:
            return 'ITALIAN';
        case PORTUGUESE:
            return 'PORTUGUESE';
        case FRENCH:
            return 'FRENCH';
        default:
            return language;
    }
}

export const convertColorToRgba = (color, transperant) => {

        if(!color) {
            return;
        }

        // Hex to Rgba
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)){

            let c;
            c= color.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',' + transperant + ')';
        // Hsl to Rgba
        } else if(color.match(/^hsla?\(\s?(\d+)(?:deg)?,?\s(\d+)%,?\s(\d+)%,?\s?(?:\/\s?\d+%|\s+[\d+]?\.?\d+)?\)$/i)) {

            color = color.match(/^hsla?\(\s?(\d+)(?:deg)?,?\s(\d+)%,?\s(\d+)%,?\s?(?:\/\s?\d+%|\s+[\d+]?\.?\d+)?\)$/i);

            if (!color) {
                return null;
            }
            let C,X,m,r,g,b;
            let h = color[1];
            let s = color[2];
            let l = color[3];
            s /= 100;
            l /= 100;
            C = (1 - Math.abs(2 * l - 1)) * s;
            let hue = h / 60;
            X = C * (1 - Math.abs(hue % 2 - 1));
            r = g = b = 0;
            if (hue >= 0 && hue < 1) {
                r = C;
                g = X;
            } else if (hue >= 1 && hue < 2) {
                r = X;
                g = C;
            } else if (hue >= 2 && hue < 3) {
                g = C;
                b = X;
            } else if(hue >= 3 && hue < 4) {
                g = X;
                b = C;
            } else if (hue >= 4 && hue < 5) {
                r = X;
                b = C;
            } else {
                r = C;
                b = X;
            }
            m = l - C / 2;
            r += m;
            g += m;
            b += m;
            r *= 255.0;
            g *= 255.0;
            b *= 255.0;

            return `rgba(${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}, ${transperant})`;
        // Rgb to Rgba
        } else if(color.match(/^rgba?\(\s?(\d+),?\s?(\d+),?\s?(\d+),?\s?\/?\s?(\d?\.?\d+|\d+)%?\)$/i)) {

            let newColor
            newColor = color.replace(/rgb/i, "rgba");
            newColor = newColor.replace(/\)/i,`,${transperant}`);

            return newColor
        }
}

export const checkIfServiceFeeIsWrongAndAmend = (serviceFee) => {

    if(SERVICE_FEES_IN_PERCENT_ARRAY.includes(serviceFee)) {
        serviceFee = DEFAULT_SERVICEFEE;
    }

    return serviceFee;
}

export const encryptData = (data, key) => {
    const dataString = JSON.stringify(data);
    return CryptoJS.AES.encrypt(dataString, key).toString();
}
export const decryptData = (data, key) => {
    const bytes = CryptoJS.AES.decrypt(data, key);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
}
export const hashKey = (key) => {
    return CryptoJS.SHA256(key).toString();
}
