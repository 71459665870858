import axios from '../../../utils/AxiosUtils';
import { GET_USER_ADDRESS_DELIVERY_ZONE, SHOW_ERROR_DIALOG } from '../../types';

export const restaurantChainService = (address, callback) => {
    return (dispatch, getState) => {

        const restaurantChainId = getState().brand.id;
        const isPwaAppRunning = getState().pwaAppRunning;
        const queryParameters = `?longitude=${address.longitude}&latitude=${address.latitude}&zipCode=${address.zipCode}&isApp=${isPwaAppRunning}`;

        const url = `/restaurant-chain/${restaurantChainId}/restaurant/delivery${queryParameters}`;

        axios(getState).get(url)
            .then(response => getDeliveryZoneAndSaveAddress(dispatch, response, callback))
            .catch(_ => {

                dispatch({ type: SHOW_ERROR_DIALOG, payload: true });
            });
    }
};

const getDeliveryZoneAndSaveAddress = (dispatch, response, callback) => {

    dispatch({ type: GET_USER_ADDRESS_DELIVERY_ZONE, payload: response.data });
    callback(response.data);
};