// Server API URLs

const SERVER_URLS = {
    LOCAL: 'http://192.168.0.161:8080/menuu-dev/api',
    DEVELOPMENT: 'https://api.menuu.eu/menuu-dev/api',
	STAGING: 'https://api.menuu.eu/menuu/api',
    PRODUCTION: 'https://api.menuu.com/menuu/api',
    QA: 'https://api.menuu.eu/menuu-qa/api',
    AMAZON_DEV: 'https://api-dev.menuu.com/menuu-qa/api'
};

const FRONT_END_URLS = {
    LOCAL: 'http://192.168.0.161:3001',
    DEVELOPMENT: 'https://frontend-dev.menuu.eu',
	STAGING: 'https://frontend.menuu.eu',
    PRODUCTION: 'https://frontend.menuu.com',
    QA: 'https://frontend-qa.menuu.eu',
    AMAZON_DEV: 'https://frontend-dev.menuu.com'
}

const SIMPLE_BILLING_KEYS = {
    DEVELOPMENT: 'pk_test_xX7F3tHjq6yeuz8lCMTSPZAc',
    STAGING: 'pk_live_b8gEfYzbsbRMaPUgybOfRzG400scYXPBlA',
    PRODUCTION: 'pk_live_b8gEfYzbsbRMaPUgybOfRzG400scYXPBlA',
    QA: 'pk_test_xX7F3tHjq6yeuz8lCMTSPZAc'
};

export const SERVER_URL = SERVER_URLS.QA; // SERVER_URLS.STAGING
export const FRONT_END_URL = FRONT_END_URLS.QA; // FRONT_END_URL.STAGING
export const FRONT_END_URL_DEV = FRONT_END_URLS.DEVELOPMENT;
export const FRONT_END_URL_QA = FRONT_END_URLS.QA;
export const SIMPLE_BILLING_KEY = SIMPLE_BILLING_KEYS.PRODUCTION;

export const PWA_VERSION = 22.00;

// Dev and QA
export const AUTHIPAY_GATEWAY_LINK = 'https://test.ipg-online.com/connect/gateway/processing';
// Production and staging
// export const AUTHIPAY_GATEWAY_LINK = 'https://www.ipg-online.com/connect/gateway/processing';

// Dev and QA
export const MONERIS_LINK = 'https://esqa.moneris.com/HPPtoken/index.php';
// Production and Staging
// export const MONERIS_LINK = 'https://www3.moneris.com/HPPtoken/index.php';

export const DELIVERY_ID = 1;
export const COLLECTION_ID = 2;
export const CURBSIDE_ID = 4;
export const IN_STORE_ID = 5;

export const DELIVERY_INDEX = 1;
export const COLLECTION_INDEX = 2;
export const DINE_IN_INDEX = 3;
export const CURBSIDE_INDEX = 4;
export const INSTORE_INDEX = 5;

// Application types
export const SPA_APP = 'spa';
export const PWA_APP = 'pwa';

// Google maps Api key
// Dev and staging
// export const GOOGLE_API_KEY = 'AIzaSyC-g0fFlKbpC9o6d7Pk4Ec6BrT5joPTzcQ';
// Production
export const GOOGLE_API_KEY = 'AIzaSyDSzlwwJACmlQhi5jI9x4_Eqh8-CN8OBbQ';

// Country codes
export const US = 'US';
export const GB = 'GB';
export const IE = 'IE';
export const DE = 'DE';
export const NL = 'NL';
export const BE = 'BE';
export const FR = 'FR';
export const BG = 'BG';
export const CA = 'CA';
export const МТ = 'MT';
export const IN = 'IN';
export const AU = 'AU';
export const BR = 'BR';
export const PT = 'PT';
export const IT = 'IT';
export const ES = 'ES';
export const PL = 'PL';
export const MX = 'MX';
export const AR = 'AR';
export const CL = 'CL';

// Product types
export const NO_QUESTION_PRODUCT = 'noQuestionsProduct';
export const PIZZA_PRODUCT = 'pizzaProduct';
export const GENERAL_PRODUCT = 'generalProduct';

// Pwa Header Icons Controls
export const NAV_ICON_HEIGHT = 32;
export const NAV_ICON_WIDTH = 32;
export const NAV_ICON_COLOR = "#fff";
export const NAV_ICON_STROKE_OPTIONS = {
    stroke: "#343a4061",
    strokeWidth: 1
};

export const SUPPORTED_APPLE_PAY_CARDS = [
    'visa',
    'masterCard',
    'amex',
    'cartesBancaires',
    'chinaUnionPay',
    'discover',
    'eftpos',
    'electron',
    'elo',
    'interac',
    'jcb',
    'mada',
    'maestro',
    'privateLabel',
    'vPay'
];

//order source
export const WEB = "WEB";
export const WEB_IOS = "WEB_IOS";
export const WEB_ANDROID = "WEB_ANDROID";
export const APP_IOS = "APP_IOS";
export const APP_ANDROID = "APP_ANDROID";

// Badge
export const PAUSED = 'PAUSED';
export const PRE_ORDER = 'PRE_ORDER';
export const ORDER = 'ORDER';

// Languages
export const ARABIC = 'ar';
export const ENGLISH = 'en';
export const ITALIAN = 'it';
export const BULGARIAN = 'bg';
export const PORTUGUESE = 'pt';
export const FRENCH = 'fr';
export const SPANISH = 'es';

// ReCaptcha Site Key
export const reCaptchaSiteKey = '6Lfg4AEhAAAAALM7yMZ8xRmD7eO80wWJG2qX_eK4';

//Issue with wrongly set service fee in apps
export const DEFAULT_SERVICEFEE = 0.98;
export const SERVICE_FEES_IN_PERCENT_ARRAY = [10];

// Social logins
export const GOOGLE_CLIENT_ID = '585116252797-4df9djl7ftjjaspupocl43bnoap5rolj.apps.googleusercontent.com';
export const FACEBOOK_APP_ID = '1088244646225499';
export const APPLE_CLIENT_ID = 'com.menuu.apple.signin.services';
export const GENERIC_API_KEY = 'jsUhgEGoxnb5ItKsMLYjicXwpxfIYMJ88JAPOGEJQs29iehwMI8N7gbxUeE9yiWvZqtOFKz52JpmsO49KollsWM0bd9ovFDAKGiHixzqWpmyuGCwne3DVMUnpfL9JKFO';
