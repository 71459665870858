import React, { Component } from "react";
import { connect } from 'react-redux';
import { Input, Col, Row, Container } from 'reactstrap';
import i18n from "../../../i18next";

import { INPUT_FIELDS_BG_COLOR } from '../../../utils/StylesConstants';
import { saveCustomerDeliveryAddress, restaurantChainService, saveSelectedRestaurantData, updateCustomerDeliveryAddresses } from '../../../actions/index';
import TickSVG from '../../../pwa-app/components/common/svgComponents/TickSVG';
import { ARABIC } from "../../../utils/Constants";

class ConfirmAddressContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buildingNumber: '',
            fullAddress: '',
            street: '',
            town: '',
            zipCode: '',
        };

    }

    componentDidMount() {
        this.applyTheme();

        if (this.props.userAddress && this.props.userAddress.fullAddress && this.props.userAddress.street) {
            this.setState({
                ...this.state,
                fullAddress: this.props.userAddress.fullAddress,
                street: this.props.userAddress.street.includes('Unname') ? '' : this.props.userAddress.street,
                town: this.props.userAddress.city,
                buildingNumber: this.props.userAddress.streetNumber ? this.props.userAddress.streetNumber : '',
                zipCode: this.props.userAddress.zipCode ? this.props.userAddress.zipCode : ''
            });
        }
    }

    handleInputChange({ target }) {
        this.setState({ ...this.state, [target.name]: target.value }, () => this.props.updateAddressInParent(this.state));
    }

    renderConfirmAddressForm() {
        const { inputFieldStyle, invalidInputFieldStyle, inputFieldContainer, greenTickIconStyle, greenTickIconStyleRTL } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        // Check for invalid fields
        const buildingNumberInvalid = this.state.buildingNumber.length === 0 || this.state.buildingNumber === '';
        const streetInvalid = !(this.state.street !== '' && !this.state.street.toLowerCase().includes('unname'));
        const townInvalid = this.state.town === null || this.state.town.length === 0 || this.state.town === '';
        const zipCodeInvalid = this.state.zipCode.length === 0 || this.state.zipCode === '' || this.state.zipCode.length > 31;

        // Set input field styles
        let buildingNumberFieldStyle, streetFieldStyle, townFieldStyle, zipCodeFieldStyle;

        (buildingNumberInvalid) ? buildingNumberFieldStyle = invalidInputFieldStyle : buildingNumberFieldStyle = inputFieldStyle;
        (streetInvalid) ? streetFieldStyle = invalidInputFieldStyle : streetFieldStyle = inputFieldStyle;
        (townInvalid) ? townFieldStyle = invalidInputFieldStyle : townFieldStyle = inputFieldStyle;
        (zipCodeInvalid) ? zipCodeFieldStyle = invalidInputFieldStyle : zipCodeFieldStyle = inputFieldStyle;

        return (
            <React.Fragment>
                <Container style={{padding: 0, marginTop: 16}}>
                    <Row className="confirmAddressContainer" style={{ display: 'flex', justifyContent: 'center'}}>
                        <Col xs={12} sm={12} md={10} style={inputFieldContainer}>
                            <Input placeholder={i18n.t('screens:confirmAddressScreen.buildingOrApartmentNum')}
                                   name="buildingNumber" maxLength="64"
                                   className='addressInputs'
                                   style={buildingNumberFieldStyle}
                                   value={this.state.buildingNumber}
                                   onChange={this.handleInputChange.bind(this)}
                            />
                            {this.state.buildingNumber ?
                                this.state.buildingNumber.length ?
                                    <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle}/> : null
                                : null
                            }
                        </Col>
                        <Col xs={12} sm={12} md={10}>
                            <Input placeholder={i18n.t('screens:confirmAddressScreen.userAddress')}
                                   name="street"
                                   className='addressInputs'
                                   autoComplete="off"
                                   style={streetFieldStyle}
                                   value={this.state.street}
                                   onChange={this.handleInputChange.bind(this)}
                            />
                            {
                                (!streetInvalid) ? <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle}/> : null
                            }
                        </Col>
                        <Col xs={12} sm={12} md={5}>
                            <Input placeholder={i18n.t('screens:confirmAddressScreen.userTown')}
                                   name="town"
                                   className='addressInputs'
                                   style={townFieldStyle}
                                   value={this.state.town}
                                   onChange={this.handleInputChange.bind(this)}
                            />
                            {this.state.town ?
                                this.state.town.length ?
                                    <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle}/> : null
                                : null
                            }
                        </Col>
                        <Col xs={12}  sm={12} md={5}>
                            <Input placeholder={i18n.t('screens:confirmAddressScreen.postCode')}
                                   name="zipCode"
                                   className='addressInputs'
                                   style={zipCodeFieldStyle}
                                   value={this.state.zipCode}
                                   onChange={this.handleInputChange.bind(this)}
                            />
                            {this.state.zipCode ?
                                this.state.zipCode.length && this.state.zipCode.length < 31 ?
                                    <TickSVG width={30} height={30} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? greenTickIconStyleRTL : greenTickIconStyle}/> : null
                                : null
                            }
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { startOrderFontSize, chefNoteBackground } = this.props.customerThemes.selectedTheme;
        customizedStyles.screenMessage.fontSize = startOrderFontSize;
        customizedStyles.inputFieldStyle.backgroundColor = chefNoteBackground;
        customizedStyles.greenTickIconStyle.backgroundColor = chefNoteBackground;
        customizedStyles.greenTickIconStyleRTL.backgroundColor = chefNoteBackground;
        styles = customizedStyles;
        return styles;
    }

    render() {

        return (
            <React.Fragment>
                {this.renderConfirmAddressForm()}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        // customer: state.customer,
        userAddress: state.customer.selectedDeliveryAddress,
        // selectedOrderType: state.orderTypes.selectedOrderType,
        customerThemes: state.customerThemes,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, { saveCustomerDeliveryAddress, restaurantChainService, saveSelectedRestaurantData, updateCustomerDeliveryAddresses })(ConfirmAddressContent);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    screenMessage: {
        textAlign: 'center',
        width: '100%',
        marginBottom: 0
    },
    inputFieldStyle: {
        height: 55,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: INPUT_FIELDS_BG_COLOR,
        marginBottom: 10,
        fontWeight: 300
    },
    invalidInputFieldStyle: {
        height: 55,
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 10,
        backgroundColor: '#F2D8D9',
        fontWeight: 300
    },
    navigateNextIcon: {
        position: 'absolute',
        top: 3
    },
    modalHeaderStyle: {
        textAlign: 'center',
        fontSize: 22,
        marginBottom: 0,
        marginLeft: 70
    },
    inputFieldContainer: {
        position: 'relative',
        fontWeight: 300
    },
    greenTickIconStyle: {
        width: 30,
        height: 'auto',
        position: 'absolute',
        top: 13,
        right: 23,
        backgroundColor: '#f0f0f0',
        padding: 5
    },
    greenTickIconStyleRTL: {
        width: 30,
        height: 'auto',
        position: 'absolute',
        top: 13,
        left: 23,
        backgroundColor: '#f0f0f0',
        padding: 5
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72
    },
    login: {
        fontSize: 22,
        marginLeft: 12,
        marginRight: 12
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    imageStyle: {
        paddingRight: 18,
        marginTop: 0
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center',
        paddingRight: 55
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: 20
    }
};