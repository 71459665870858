import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { Alert, Button, Col, Modal } from "reactstrap";
import { GridLoader } from "react-spinners";
import i18n from '../../i18next';

import UnavailableProductsScreen from "../order/UnavailableProductsScreen";
import OrderDetailsScreen from "./OrderDetailsScreen";

import {
    loadOrderHistory, addProductToCart, changeOrderTimes, saveNewOrderTime,
    clearTemporaryOrderTimeData, loadOrderProducts, clearMessageAlert, setMenuOrOrderHistoryScreen,
    voidPurchase,
    resetPromoCodeField,
    resetOldDiscountFlag,
    cancelComoPayment
} from "../../actions/index";
import { ARABIC, COLLECTION_ID, DELIVERY_ID } from "../../utils/Constants";
import {
    transformProductPrice,
    transformProductsComingFromServer,
    getPriceWithCurrencySymbol
} from "../../utils/CommonUtils";
import { updateCSSVariables } from '../../utils/ThemesSelector';
import ConfirmationModal from '../common/ConfirmationModal';
import ShoppingCartSVG from '../../pwa-app/components/common/svgComponents/ShoppingCartSVG';

class OrderHistoryScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allProductsUnavailable: false,
            selectedOrder: {},
            isConfirmOrderScreenOpened: false,
            isOrderDetailsScreenOpened: false,
            isOrderNow: false,
            locationPath: '',
            openConfirmationDialog: false,
            selectedOrder: null
        }
        this.toggleConfirmOrderScreen = this.toggleConfirmOrderScreen.bind(this);
        this.toggleOrderDetailsScreen = this.toggleOrderDetailsScreen.bind(this);
        // this.getOrderProducts = this.getOrderProducts.bind(this);
    }

    componentWillMount() {
        if (this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }
        this.props.loadOrderHistory(this.props.brandId);
        this.state.locationPath = this.props.history.location.state;

    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme.colorsCss !== prevProps.customerThemes.selectedTheme.colorsCss && this.props.customerThemes.selectedTheme.colorsCss) {
            this.applyTheme();
        }
    }

    componentWillUnmount() {
        const isCheckoutScreen = window.location.href.includes('checkout');
        const isComo = this.props.promotionPartners.promotionPartner && this.props.promotionPartners.promotionPartner.id === 2;
        const isLoggedInPromotionPartner = this.props.promotionPartners.isUserLoggedInPartner;

        if (isComo && isLoggedInPromotionPartner && !isCheckoutScreen) {
            const onSuccess = () => {
                this.props.voidPurchase(() => this.props.resetOldDiscountFlag());
                this.props.resetPromoCodeField();
            };

            this.props.cancelComoPayment(onSuccess);
        }
    }

    toggleConfirmOrderScreen(isOrderNow) {
        this.setState({
            ...this.state,
            isConfirmOrderScreenOpened: !this.state.isConfirmOrderScreenOpened,
            isOrderNow: isOrderNow
        }, () => {
            if (!this.state.isConfirmOrderScreenOpened) {
                this.props.clearTemporaryOrderTimeData();
            }
        });
    }

    toggleOrderDetailsScreen(selectedOrder) {
        this.setState({
            ...this.state,
            isOrderDetailsScreenOpened: !this.state.isOrderDetailsScreenOpened,
            selectedOrder: selectedOrder
        });
    }

    renderErrorAlert() {
        const errorAlert = this.props.alertMessage.showErrorAlert;
        const dismiss = this.props.clearMessageAlert.bind(this, 'error');

        if (errorAlert) {
            return ( // Easy way attach remove event to the dismis toggle
                <div className='alertContainer'>
                    <Alert className='error-alert' color='danger' toggle={dismiss} style={{ borderRadius: 0 }}>
                        {i18n.t('screens:profileScreen.productsFromDifferentBrandPartOne')}
                        {i18n.t('screens:profileScreen.productsFromDifferentBrandPartTwo')}
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }

    getOrderProducts(order, isOrderNow, isAddToCart) {
        console.log(order, 'ORDERRRRRR');
        this.props.loadOrderProducts(order.id, () => this.getAvailableOrderProducts(isOrderNow, isAddToCart, order));
    }

    getAvailableOrderProducts(isOrderNow, isAddToCart, order) {
        const orderTimesDate = this.props.estimateOrderTime;
        const orderId = order.id;
        const orderProductsLength = order.orderProducts.length;
        const restaurantId = this.props.selectedRestaurant.restaurantId;

        this.props.changeOrderTimes(orderTimesDate, restaurantId, true, isOrderNow, orderId, false, isAddToCart, (allProductsUnavailable, unAvailableOrderedProductsLength) => {
            if (orderProductsLength == unAvailableOrderedProductsLength) {
                this.state.allProductsUnavailable = true;
            } else {
                this.state.allProductsUnavailable = allProductsUnavailable;
            }
            this.toggleConfirmOrderScreen(isOrderNow);
        });
    }

    confirmRemoveProducts() { // when u click yes
        this.props.saveNewOrderTime(this.state.isOrderNow);
    }

    toggleConfirmationModal(order) {
        this.setState({
            openConfirmationDialog: !this.state.openConfirmationDialog,
            selectedOrder: order
        });
    }

    renderOrderHistoryContent() {
        const { mainContainer, orderButtonsStyle, orderNowBtn, addToCartBtn, priceContainer, priceContainerRTL, totalPrice, totalPriceRTL, addToCartIcon, addToCartIconRTL, spaceBetweenBtns, viewDetails } = styles;
        const { type, primaryColor, secondaryColor, mainTextColor, primaryFontColor, buttonsFont } = this.props.customerThemes.selectedTheme;
        const pwaRoundedEdgesStyle = this.props.pwaAppRunning ? { borderRadius: 0 } : {}
        const isArabic = this.props.language === ARABIC;

        const isMobileView = (window.outerWidth <= 575) ? true : false;
        const errorMsgStyle = (this.props.alertMessage.showErrorAlert) ? (isMobileView) ? { top: '25%', left: 0, position: 'fixed', zIndex: 2 } : isArabic ? { paddingRight: '4%', paddingTop: '0%', position: 'fixed', zIndex: 2 } : { paddingLeft: '4%', paddingTop: '0%', position: 'fixed', zIndex: 2 } : {};
        const currency = this.props.selectedRestaurant.currency;
        const textData = {
            informationMessage: i18n.t('screens:customerOrderHistoryScreen.informationMessage')
        };

        return (
            <Fragment>
                <div style={errorMsgStyle}>
                    {this.renderErrorAlert()}
                </div>
                {
                    this.props.orderHistory.length ?
                        this.props.orderHistory.map((order) => {
                            const orderDateText = (order.orderType.id == DELIVERY_ID) ? i18n.t('screens:profileScreen.delivered') : (order.orderType.id == COLLECTION_ID) ? i18n.t('screens:profileScreen.pickedUp') : '';
                            const orderDate = (order.acceptedTime !== null) ? order.acceptedTime.slice(0, 10) : order.wantedTime.slice(0, 10);
                            const orderProducts = order.orderProducts;
                            const isMobileView = (window.outerWidth <= 575) ? true : false;
                            const buttonsColStyle = (isMobileView) ? { paddingRight: 0, paddingTop: 20, float: 'left' } : isArabic ? { paddingLeft: 0, float: 'left' } : { paddingRight: 0, float: 'right' };
                            const orderDateStyle = (isMobileView) ? { fontSize: 14, color: mainTextColor, textAlign: 'left' } : isArabic ? { float: 'left', fontSize: 14, color: mainTextColor } : { float: 'right', fontSize: 14, color: mainTextColor };
                            const orderTotalPrice = transformProductPrice(order.total);
                            const orderTotalPriceWithCurrency = getPriceWithCurrencySymbol(orderTotalPrice, currency, isArabic);
                            const totalTaxPrice = transformProductPrice(order.totalTax);
                            const totalTaxPriceWithCurrency = getPriceWithCurrencySymbol(totalTaxPrice, currency, isArabic);
                            const { isUserLoggedInPartner } = this.props.promotionPartners;

                            return (
                                <Col key={`orderId_${order.id}`} sm={12} md={12} style={Object.assign({}, mainContainer, pwaRoundedEdgesStyle)}>
                                    <p style={orderDateStyle}><span style={isArabic ? {float: 'right', paddingLeft: '3px'} : {paddingRight: '3px'}}>{orderDateText}</span>{orderDate}</p>
                                    <p style={{ fontSize: '1rem', color: primaryFontColor }}>{this.renderOrderProducts(orderProducts)}</p>
                                    <Col xs={12} sm={12} md={12} className='displayContents'>
                                        <Col xs={12} sm={6} md={6} style={isArabic ? priceContainerRTL : priceContainer} className='displayContents'>
                                            <span style={Object.assign({}, isArabic ? totalPriceRTL : totalPrice, isArabic ? {float: 'right'} : null)}><span style={isArabic ? {float: 'right'} : null}>{i18n.t('screens:shoppingCart.total')}</span><span style={isArabic ? {float: 'right', paddingLeft: '3px'} : null}>:</span> {orderTotalPriceWithCurrency}</span>
                                            <span hidden={order.totalTax == 0} style={isArabic ? { textAlign: 'left', marginBottom: 0, fontSize: 16, paddingLeft: 5, color: mainTextColor } : { textAlign: 'right', marginBottom: 0, fontSize: 16, paddingRight: 5, color: mainTextColor }}>
                                                &nbsp;&nbsp;{i18n.t('screens:shoppingCart.vat')}: {totalTaxPriceWithCurrency}</span>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} className='displayContents'>
                                            <span style={{ color: '#b4b4b4' }}>&nbsp;{this.props.pwaAppRunning ? '' : '|'}&nbsp;&nbsp;</span>
                                            <span style={viewDetails} onClick={() => this.toggleOrderDetailsScreen(order)}>{i18n.t('screens:profileScreen.viewDetails')}</span>
                                        </Col>
                                        <Col xs={12} sm={4} md={6} style={buttonsColStyle} className="orderHistoryButtons">
                                            <Col xs={3} sm={2} md={2} className='displayContents'>
                                                <Button
                                                    className="outlineEffect"
                                                    outline
                                                    color="#62b400"
                                                    style={Object.assign({}, orderButtonsStyle, addToCartBtn, { float: 
                                                        isArabic ? 'left' : 'right' }, pwaRoundedEdgesStyle)}
                                                    onClick={() => isUserLoggedInPartner ? this.toggleConfirmationModal(order) : this.getOrderProducts(order, false, true)}
                                                >
                                                    <span style={isArabic ? addToCartIconRTL : addToCartIcon}>
                                                        <ShoppingCartSVG width={20} height={20} fill={type === 'DARK' ? secondaryColor : primaryColor} />
                                                    </span>
                                                    <span>{i18n.t('common:buttons.addToCart')}</span>
                                                </Button>
                                            </Col>
                                            <span style={spaceBetweenBtns}></span>
                                        </Col>
                                    </Col>
                                    <Modal className="modalStep" isOpen={this.state.isConfirmOrderScreenOpened} size='lg'>
                                        <UnavailableProductsScreen
                                            isOpen={this.state.isConfirmOrderScreenOpened}
                                            toggle={() => this.toggleConfirmOrderScreen(null)}
                                            onConfirm={() => this.confirmRemoveProducts()}
                                            onCancel={() => this.toggleConfirmOrderScreen(null)}
                                            isOrderHistoryScreen={true}
                                            allProductsUnavailable={this.state.allProductsUnavailable}
                                        />
                                    </Modal>
                                </Col>
                            )
                        }) :

                        <div style={this.props.pwaAppRunning ? { display: 'flex', justifyContent: 'center', alignSelf: 'center', height: '100%', maxHeight: 'calc(70vh - 85px)', fontSize: '1.25em', color: primaryFontColor } : { paddingLeft: '3%', color: primaryFontColor, textAlign: 'center' }}>
                            {i18n.t('screens:profileScreen.noPrevOrders')}
                        </div>
                }
                <Modal isOpen={this.state.isOrderDetailsScreenOpened} size='lg'>
                    <OrderDetailsScreen
                        isOpen={this.state.isConfirmOrderScreenOpened}
                        currency={currency}
                        selectedOrder={this.state.selectedOrder}
                        selectedRestaurant={this.props.selectedRestaurant}
                        toggle={() => this.toggleOrderDetailsScreen(this.state.selectedOrder)}
                        customerThemes={this.props.customerThemes} 
                        />
                </Modal>
                { this.state.openConfirmationDialog &&
                    <ConfirmationModal
                        isOpen={this.state.openConfirmationDialog}
                        onToggle={this.toggleConfirmationModal.bind(this)}
                        onConfirm={() => this.getOrderProducts(this.state.selectedOrder, false, true)}
                        order={this.state.selectedOrder.id}
                        message={textData}
                        customerThemes={this.props.customerThemes}
                    />
                }
            </Fragment>
        );
    }

    renderOrderProducts(products) {
        const orderProducts = transformProductsComingFromServer(products);
        let orderedProducts = '';
        const isArabic = this.props.language === ARABIC;
        console.log(isArabic)

        orderProducts.map(product => {
            if (product.length > 1) {  //MEAL DEAL
                let mealDeal = product.slice(0, 1);
                if (mealDeal[0].quantity > 1) {
                    orderedProducts += mealDeal[0].quantity + ' x ' + mealDeal[0].name + ', ';
                } else {
                    orderedProducts += mealDeal[0].name + ', ';
                }
            } else {
                if (product.quantity > 1 && isArabic === true) {
                    orderedProducts += `${product.name} x ${product.quantity} ${product.sizeName !== null ? product.sizeName : ''}, `
                } else if (product.quantity > 1) {
                    orderedProducts += `${product.quantity} x ${product.name} ${product.sizeName !== null ? product.sizeName : ''}, `
                } else {
                    orderedProducts += `${product.name} ${product.sizeName !== null ? product.sizeName : ''}, `
                }
            }
        });

        return orderedProducts.slice(0, -2);
    }

    renderLoaderContainer() {
        const { loadingIndicator } = styles;
        const customerThemeDataNotLoaded = !this.props.customerThemes && !this.props.customerThemes.selectedTheme && !this.props.customerThemes.constantStyles;

        if (customerThemeDataNotLoaded) {
            return null;
        }

        const { primaryColor } = this.props.customerThemes.selectedTheme;
        const { loadingContainer } = this.props.customerThemes.constantStyles;

        if (this.props.loading.backgroundLoader) {
            return (
                <div style={loadingContainer}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={20} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;
        const { loadingContainerTransparent } = this.props.customerThemes.constantStyles;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={20} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    applyTheme() {
        const { type, colorsCss, primaryColor, secondaryColor, fontWeight, textDecoration, menuItemsBackground, primaryFontColor } = this.props.customerThemes.selectedTheme;
        updateCSSVariables(colorsCss);
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        customizedStyles.screenTitleContainer.color = primaryColor;
        customizedStyles.screenTitleContainerRTL.color = primaryColor;
        customizedStyles.screenTitleContainer.fontWeight = fontWeight;
        customizedStyles.screenTitleContainerRTL.fontWeight = fontWeight;
        customizedStyles.viewDetails.color = primaryColor;
        customizedStyles.viewDetails.fontWeight = fontWeight;
        customizedStyles.viewDetails.textDecoration = textDecoration;
        customizedStyles.orderNowBtn.backgroundColor = primaryColor;
        customizedStyles.orderNowBtn.border = 'none';
        customizedStyles.addToCartBtn.borderColor = type === 'DARK' ? secondaryColor : primaryColor;
        customizedStyles.addToCartBtn.color = primaryColor;
        customizedStyles.mainContainer.backgroundColor = menuItemsBackground
        customizedStyles.totalPrice.color = primaryFontColor;
        customizedStyles.totalPriceRTL.color = primaryFontColor;
        styles = customizedStyles;
        return styles;
    }

    render() {
        return (
            <div>
                <Col xs={12} sm={12} md={12} className='marginAuto orderHistoryContainer'>
                    {this.renderOrderHistoryContent()}
                </Col>
                {this.renderLoaderContainer()}
                {this.renderTransparentBackgroundLoaderContainer()}
            </div>
        );
    }
}

const mapStateToProps = state => {

    return {
        customer: state.customer,
        loading: state.loading,
        orderHistory: state.orderHistory,
        shoppingCart: state.shoppingCart,
        selectedRestaurant: state.selectedRestaurant,
        estimateOrderTime: state.estimateOrderTime,
        brandId: state.brand.id,
        customerThemes: state.customerThemes,
        errorMessage: state.errorMessage,
        alertMessage: state.alertMessage,
        pwaAppRunning: state.pwaAppRunning,
        como: state.como,
        promotionPartners: state.promotionPartners,
        language: state.storeLocalStorage.language
    };
};

export default connect(mapStateToProps, {
    loadOrderHistory,
    addProductToCart,
    changeOrderTimes,
    saveNewOrderTime,
    clearTemporaryOrderTimeData,
    loadOrderProducts,
    clearMessageAlert,
    setMenuOrOrderHistoryScreen,
    voidPurchase,
    resetPromoCodeField,
    resetOldDiscountFlag,
    cancelComoPayment
}, null, { pure: false })(OrderHistoryScreen);

let styles = {
    mainContainer: {
        backgroundColor: '#fff',
        border: '1px solid lightgray',
        margin: 'auto',
        marginBottom: 20,
        paddingTop: 12,
        display: 'inline-block'
    },
    screenTitleContainer: {
        borderBottom: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'blue',
        alignSelf: 'flex-start',
        borderColor: 'rgb(222, 226, 230)',
        paddingLeft: 0,
        marginTop: 30,
        marginBottom: 10,
        color: '#62b400'
    },
    screenTitleContainerRTL: {
        borderBottom: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'blue',
        alignSelf: 'flex-start',
        borderColor: 'rgb(222, 226, 230)',
        paddingRight: 0,
        marginTop: 30,
        marginBottom: 10,
        color: '#62b400'
    },
    screenTitleIcon: {
        float: 'left',
        marginRight: 6
    },
    screenTitleIconRTL: {
        float: 'right',
        marginLeft: 6
    },
    screenTitle: {
        marginBottom: 2
    },
    orderButtonsStyle: {
        border: '1px solid #62b400',
        paddingBottom: 5,
        height: 46,
        marginBottom: 14
    },
    orderNowBtn: {
        backgroundColor: "#62b400",
        color: '#fff'
    },
    addToCartBtn: {
        backgroundColor: "transparent",
        color: '#62b400'
    },
    priceContainer: { 
        flexDirection: 'column',
        float: 'right' 
    },
    priceContainerRTL: { 
        flexDirection: 'column',
        float: 'left' 
    },
    totalPrice: {
         textAlign: 'left' 
    },
    totalPriceRTL: {
        textAlign: 'right' 
   },
    addToCartIcon: { 
        float: 'left', 
        marginRight: 7, 
        paddingRight: 0, 
        marginTop: -2 
    },
    addToCartIconRTL: { 
        float: 'right', 
        marginLeft: 7, 
        paddingLeft: 0, 
        marginTop: -2 
    },
    spaceBetweenBtns: {
        width: 10
    },
    productThumbnailCol: { 
        float: 'left', 
        textAlign: 'left', 
        paddingRight: 0, 
        paddingLeft: 0, 
        marginLeft: 0, 
        height: '100%' 
    },
    productThumbnailColRTL: { 
        float: 'right', 
        textAlign: 'right', 
        paddingRight: 0, 
        paddingLeft: 0, 
        marginRight: 0, 
        height: '100%' 
    },
    productThumbnailImg: { 
        width: '125px', 
        height: '100%', 
        objectFit: 'cover' 
    },
    productNameCol: { 
        float: 'right', 
        paddingRight: 0,
        paddingLeft: 0 
    },
    productNameColRTL: { 
        float: 'left', 
        paddingRight: 0,
        paddingLeft: 0 
    },
    productQty: { 
        float: 'left', 
        marginRight: 6 
    },
    productQtyRTL: { 
        float: 'right', 
        marginLeft: 6 
    },
    productQuestions: { 
        color: 'grey', 
        fontSize: 12, 
        marginBottom: 2 
    },
    mealDealName: { 
        marginBottom: 10, 
        fontSize: 18 
    },
    mealDealProductName: { 
        marginBottom: 6, 
        fontSize: 14 
    },
    mealDealQuestions: {
        marginBottom: 10, 
        marginTop: -5, 
        color: 'grey', 
        fontSize: 12 
    },
    priceStyle: { 
        float: 'right', 
        fontSize: 14, 
        marginRight: -12 
    },
    priceStyleRTL: { 
        float: 'left', 
        fontSize: 14, 
        marginLeft: -12 
    },
    viewDetails: { 
        color: '#62b400',
        cursor: 'pointer' 
    }
}