import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import jwtDecode from "jwt-decode";
import { APPLE_CLIENT_ID, GENERIC_API_KEY } from "../../../utils/Constants";
import { saveAppleUserData, newSocialLogin, submitLogin, startOrderScreenPath, activateLoader, stopLoader } from '../../../actions';
import { encryptData, hashKey } from '../../../utils/CommonUtils';

class AppleSignInAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      userFirstName: '',
      userEmail: ''
    };
    
    this.userSignInSuccess = this.userSignInSuccess.bind(this);
    this.userSignInFailure = this.userSignInFailure.bind(this);
  }

  componentDidMount() {
    this.props.activateLoader();
    if (window.AppleID && window.AppleID.auth) {
      this.initializeAppleSignIn();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.newSocialLoginInfo !== prevProps.newSocialLoginInfo && this.props.newSocialLoginInfo) {
      if (this.state.userData && this.props.newSocialLoginInfo.providerName === 'apple') {

        let newSocialLoginInfo = this.props.newSocialLoginInfo;
        newSocialLoginInfo.userFirstName = this.state.userFirstName;
        newSocialLoginInfo.userEmail = this.state.userEmail;

        this.props.saveAppleUserData(this.state.userData);
        this.props.stopLoader();

        if (this.props.isCheckoutLogin) {
          const path = '/menu-screen/social-response';
          this.props.startOrderScreenPath(path);
        } else {
          this.props.history.push('/start-order/social-response');
        }
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('AppleIDSignInOnSuccess', this.userSignInSuccess);
    document.removeEventListener('AppleIDSignInOnFailure', this.userSignInFailure);
  }

  initializeAppleSignIn() {
    const randState = this.randomStringGenerator(12);
    const randNonce = this.randomStringGenerator(12);

    window.AppleID.auth.init({
      clientId: APPLE_CLIENT_ID,
      scope: 'email name',
      redirectURI: 'https://frontend-qa.menuu.eu',
      state: `stat${randState}`,
      nonce: `nonc${randNonce}`,
      usePopup: true
    });

    document.addEventListener('AppleIDSignInOnSuccess', this.userSignInSuccess);
    document.addEventListener('AppleIDSignInOnFailure', this.userSignInFailure);

    this.props.stopLoader();
  }

  randomStringGenerator(length) {
    for (var str = ''; str.length < length; str += 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.charAt(Math.random()*62|0));
    return str;
  }

  userSignInSuccess(event) {
    if (event.detail) {

      let userData = {};
      let appleUserEmail = '';
      userData.socialProvider = "apple";
      userData.isFirstLogin = false;

      if (event.detail.user) {
        userData.isFirstLogin = true;
        let userFirstName = '';
        let userLastName = '';
        if (event.detail.user.name.firstName) {
          userFirstName = event.detail.user.name.firstName;

          this.setState({
            userFirstName: userFirstName
          });
        }
        if (event.detail.user.name.lastName) {
          userLastName = event.detail.user.name.lastName;
        }
        userData.name = `${userFirstName} ${userLastName}`;
        if (event.detail.user.email) {
          appleUserEmail = event.detail.user.email;
        }
      }

      if (event.detail.authorization.id_token) {
        const userDecode = jwtDecode(event.detail.authorization.id_token);

        userData.jwtToken = event.detail.authorization.code;
        
        if (userDecode.sub) {
          userData.accountId = userDecode.sub;
        }
        if (userDecode.email && !event.detail.user) {
          appleUserEmail = userDecode.email;          
        }
      }
      
      if (appleUserEmail) {
        userData.email = appleUserEmail;      	
        
        if (!event.detail.user) {
          this.setState({
            userEmail: appleUserEmail
          });
        }
      }

      const year = new Date().getFullYear();
      const brandId = this.props.brand.id;
      const key = hashKey(`${GENERIC_API_KEY}${brandId}${year}`);

      const encrData = encryptData(userData, key);
      if (encrData) {
        this.setState({
          userData: encrData
        });
      }

      let isOrderDone = false;
      if (this.props.isOrderDone) {
        isOrderDone = true;      	
      }

      let isPWA = false;
      if (this.props.isPWA) {
        isPWA = true;
      }

      this.props.activateLoader();
      this.props.submitLogin(undefined, isOrderDone, isPWA, undefined, undefined, encrData, this.props.isCheckoutLogin);
    }
  }

  userSignInFailure(event) {
    console.error('Apple Sign In Error:', event.detail.error);
  }

  render() {
    const { buttonWrapper } = styles;

    return (
      <div style={buttonWrapper}>
        <div id="appleid-signin" data-mode="center-align" data-color="black" data-border="false" data-width="375" data-height="50" data-type="sign in"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newSocialLoginInfo: state.customer.newSocialLoginInfo,
    brand: state.brand
  };
};

export default withRouter(connect(mapStateToProps, { 
  saveAppleUserData,
  newSocialLogin,
  submitLogin,
  startOrderScreenPath,
  activateLoader,
  stopLoader  
})(AppleSignInAuth));

let styles = {
  buttonWrapper: {
    width: '400px',
    height: '50px',
    cursor: 'pointer'
  }
}
