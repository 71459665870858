import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import i18n from "../../../i18next";
import { isMobile } from 'mobile-device-detect';
import { Link, NavLink } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";

import { getOrderTypes, saveCustomerDeliveryAddress, loadOrderTypeData, loadCustomerFromLocalStorage, saveStartOrderTime, setApplicationType, changeLanguage } from '../../../actions/index';
import { SPA_APP, FRONT_END_URL, CURBSIDE_ID, ARABIC } from '../../../utils/Constants';

import moment from "moment/moment";
import ReactCountryFlag from 'react-country-flag';
import MaterialIcon from "material-icons-react";
import { formatLanguageCode, getLoggedInUserFirstName } from "../../../utils/CommonUtils";
import { COLLECTION_ID, DELIVERY_ID } from "../../../utils/Constants";
import { GridLoader } from "react-spinners";
import ModalStep from "../../common/ModalStep";
import DeliverySVG from "../../../pwa-app/components/common/svgComponents/DeliverySVG";
import ShoppingCartSVG from "../../../pwa-app/components/common/svgComponents/ShoppingCartSVG";
import UserIconSVG from "../../../pwa-app/components/common/svgComponents/UserIconSVG";
import CurbsideSVG from "../../../pwa-app/components/common/svgComponents/CurbsideSVG";

class SelectOrderTypeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userAddress: '',
            streetNumber: null,
            streetName: null,
            city: null,
            country: null,
            zipCode: null,
            userCoordinates: {
                lat: null,
                lng: null
            },
            defaultLanguage: 'en',
            isCordova: false            
        };
        
        this.onDeviceReady = this.onDeviceReady.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.customerThemes.selectedTheme !== prevProps.customerThemes.selectedTheme) {
            this.applyTheme();
        }
    }

    componentWillMount() {
        const rootElement = document.getElementById('root');
        const brandId = rootElement.getAttribute('data-brand-id');
        if (brandId) {
            this.props.getOrderTypes(brandId);
        } else if (!brandId) {
            this.props.getOrderTypes(this.props.brand.id);
        }
        this.props.loadCustomerFromLocalStorage();

        const currentTime = moment();
        this.props.saveStartOrderTime(currentTime);
    }

    componentDidMount() {

        this.props.setApplicationType(SPA_APP);

        window.addEventListener('resize', () => {
            const isLandscapeMode = window.outerHeight < 600;

            if (isLandscapeMode && document.getElementsByClassName('modal-content')[0]) {
                document.getElementsByClassName('modal-content')[0].style.height = 'auto';
                document.getElementsByClassName('modal-content')[0].style.overflow = 'auto';
            }
        });

        // Cordova deviceready listener        
        document.addEventListener("deviceready", this.onDeviceReady, false);
    }

    componentWillUnmount() {
        document.removeEventListener("resize", () => {
            const isLandscapeMode = window.outerHeight < 600;

            if (isLandscapeMode && document.getElementsByClassName('modal-content')[0]) {
                document.getElementsByClassName('modal-content')[0].style.height = 'initial';
                document.getElementsByClassName('modal-content')[0].style.overflow = 'initial';
            }
        });
        
        document.removeEventListener("deviceready", this.onDeviceReady, false);
    }

    toggleLanguageButton(language) {

        this.props.changeLanguage(language)
    }

    onDeviceReady() {
        if (window.cordova) {
          this.setState({ isCordova: true });
        }
    }

    renderModalHeader() {
        const { selectedLanguage } = this.props;
        const customerLoggedIn = this.props.customer !== undefined && this.props.customer !== null && this.props.customer.id !== undefined && this.props.customer.id !== NaN
            && this.props.customer.id !== null && this.props.customer.id !== -1 && this.props.customer.email !== undefined
            && this.props.customer.email !== null && this.props.customer.email !== "null" && this.props.customer.email !== 'undefined';
       
        return (
            <Fragment>
                <div className='start-order-header-container' style={{ position: 'relative' }}>
                    {selectedLanguage !== 'en' ?
                        this.renderSwitchLanguageButtons() : null
                    }
                    {(customerLoggedIn) ? this.renderCustomer() : ' '}
                </div>
            </Fragment>
        );
    }

    renderSwitchLanguageButtons() {

        const { selectedLanguage, isDefaultLanguage } = this.props;
        const { defaultLanguage } = this.state;
        const { fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const isMobileView = window.innerWidth <= 865;
        const defaultLanguageCountryCode = `${defaultLanguage === 'en' ? 'EN' : defaultLanguage.toUpperCase()}`

        const languageBtnTextStyle = {
            fontSize: '15px',
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        return (
            <div style={isMobileView ? { width: '100px', display: 'flex', justifyContent: 'start', position: 'absolute', paddingLeft: '10px' } : { width: '100px', display: 'flex', justifyContent: 'center', position: 'absolute' }}>
                <div>
                    <Button
                        color="outline-success"
                        style={{ height: '30px', display: 'flex', alignItems: 'center' }}
                        onClick={() => this.toggleLanguageButton(isDefaultLanguage ? selectedLanguage : defaultLanguage)}>
                        <span style={languageBtnTextStyle}>{isDefaultLanguage ? selectedLanguage.toUpperCase() : defaultLanguageCountryCode}</span>
                    </Button>
                </div>
            </div>
        )
    }

    renderBackButton() {
        const { headerColumn } = styles;
        const isMobileView = window.outerWidth <= 575;
        const displayNone = (isMobileView && this.props.orderTypes !== undefined && this.props.orderTypes !== null
            && this.props.orderTypes.length == 0) ? { display: 'none' } : null;

        return (
            <div style={Object.assign({}, headerColumn, displayNone)}>
                <Button className="backButton" >
                    <span className="backButtonIcon">
                        <MaterialIcon icon="navigate_before" color="#000" size={32} />
                    </span>
                    <span style={{ fontWeight: 300 }}>{i18n.t('common:buttons.backBtn')}</span>
                </Button>
            </div>

        )
    }

    renderCustomer() {
        const { imageStyle, imageStyleRTL, profileNameStyle, profileNameStyleRTL, centredItem } = styles;
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        const userFirstName = getLoggedInUserFirstName(this.props.customer.name);

        let customerNameClass = '';
        if (window.outerWidth <= 500) {
            customerNameClass = 'customer-long-name-no-back-button';
        }

        return (
            <div style={Object.assign({}, centredItem)} className='customer-name-mobile-style'>
                <UserIconSVG width={22} height={22} fill={type === 'DARK' ? secondaryColor : primaryColor} style={isArabic ? imageStyleRTL : imageStyle} />
                <span className={customerNameClass} style={isArabic ? profileNameStyleRTL : profileNameStyle}>{userFirstName}</span>
            </div>
        );
    }

    renderInvalidBrandId() {

        const isMobileView = window.outerWidth <= 575;
        const textStyle = (isMobileView) ? { textAlign: 'center', paddingTop: 10 } : { textAlign: 'center', paddingTop: 10, margin: '0 auto' };
        return (
            <div style={textStyle}>
                <h4>{i18n.t('screens:selectOrderTypeScreen.invalidBrandId')}</h4>
            </div>
        )
    }

    renderModalBody() {
        const { centredItem, loginTextStyle, fontStyle, buttonsContainer, loginImageIcon, loginImageIconRTL } = styles;

        const { primaryFontColor, buttonsFont, fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;

        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const isArabic = this.props.language === ARABIC;

        const { isCordova } = this.state;
        let loginBtnLink = '';
        
        if (isCordova) {
            loginBtnLink = '/start-order/phone-number'; 
        } else {
            loginBtnLink = '/start-order/login-types';        	
        }

        const brandNameStyle = {
            fontWeight,
            color: primaryFontColor,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        const titleStyle = {
            margin: 'auto',
            textAlign: 'center',
            fontSize: 22,
            color: primaryFontColor,
            fontFamily: fontFamilyType,
            fontWeight,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        const loginButtonTextStyle = {
            verticalAlign: 'middle',
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase,
            fontSize: 18
        }
        
        if (this.props.orderTypes !== undefined && this.props.orderTypes !== null && this.props.orderTypes.length > 0) {

            const isCustomerLoggedIn = this.props.customer.id && this.props.customer.email !== undefined
                && this.props.customer.email !== null && this.props.customer.email !== "null";

            return (
                <Fragment>
                    {this.renderModalHeader()}
                    <div className='modal-body-custom' style={{ padding: '0 0 30px 0', margin: 0 }}>
                        <Col sm={12}>
                            {
                                this.props.logoImagePath ?
                                    <img src={`${FRONT_END_URL}/${this.props.logoImagePath}`} style={{ maxWidth: 160 }} /> :
                                    <h3 style={brandNameStyle}>{this.props.brand.name}</h3>
                            }
                        </Col>
                        <Col sm={12} className='modal-custom-body-title' style={{ height: 57, maxHeight: 57, minHeight: 57 }}>
                            <p className='title-style' style={titleStyle}>{i18n.t('screens:selectOrderTypeScreen.heading')}</p>
                        </Col>
                        <Col xs={12} sm={10} className='modal-custom-body-inner-content orderButtonsContainer' style={buttonsContainer}>
                            <Row style={centredItem}>
                                {this.renderOrderTypes()}
                            </Row>
                            <p className='or-title-style' style={Object.assign({}, fontStyle, brandNameStyle, { margin: 10, padding: 0, color: primaryFontColor })} hidden={isCustomerLoggedIn || this.props.orderTypes.length === 1}>{i18n.t('screens:selectOrderTypeScreen.or')}</p>
                        </Col>
                        <Col sm={{ size: 10, offset: 1 }} className='modal-custom-body-footer' style={{ margin: '0 auto' }} hidden={isCustomerLoggedIn}>
                            <div style={{ justifyContent: 'center' }}>
                                <NavLink tag={Link} to={loginBtnLink} style={{ display: 'flex', justifyContent: 'center', width: '100%', textDecoration: 'none' }}>
                                    <Button className='loginButton hoverEffect' color="success" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: 50,
                                        alignItems: 'center'
                                    }}>
                                        <UserIconSVG width={28} height={28} fill={buttonsFont} style={isArabic ? loginImageIconRTL : loginImageIcon} />
                                        <span style={loginButtonTextStyle}>{i18n.t(`screens:selectOrderTypeScreen.login`)}</span>
                                    </Button>
                                </NavLink>
                            </div>
                            <p style={loginTextStyle} hidden={isCustomerLoggedIn}>{i18n.t('screens:selectOrderTypeScreen.loginInfoText')}</p>
                        </Col>
                    </div>
                </Fragment>
            );
        } else if (this.props.brand.invalidBrandId) {
            return (
                <Fragment>
                    {this.renderModalHeader()}
                    <h4 style={{ textAlign: 'center', width: '100%', position: 'absolute', top: '50%', marginTop: '-12px' }}>{i18n.t('screens:selectOrderTypeScreen.invalidBrandId')}</h4>
                </Fragment>
            )
        } else {
            return this.renderTransparentBackgroundLoaderContainer()
        }
    }

    renderOrderTypes() {
        const { orderBtnStyle } = styles;
        let hasInStoreOrdering = this.props.orderTypes.filter(type => type.id === 5)[0];
        const colWidth = this.props.orderTypes.length > 2 && !hasInStoreOrdering ? 6 : 5;

        const { fontFamilyType, fontBold, fontItalic, uppercase } = this.props.customerThemes.selectedTheme;
        const fontWeight = fontBold === 'bold' ? fontBold : 300;
        const isArabic = this.props.language === ARABIC;

        const buttonTextStyle = {
            marginRight: 40,
            verticalAlign: 'middle',
            fontSize: '1.125rem',
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        const buttonTextStyleRTL = {
            marginLeft: 40,
            verticalAlign: 'middle',
            fontSize: '1.125rem',
            fontWeight,
            fontFamily: fontFamilyType,
            fontStyle: fontItalic,
            textTransform: uppercase
        }

        let buttonsTextContainer = {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: 0,
            marginRight: 15
        }

        let buttonsTextContainerRTL = {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: 0,
            marginLeft: 15
        }

        // if (this.props.orderTypes.length > 2) {
        //     buttonsTextContainer.paddingLeft = 0;
        // }

        return this.props.orderTypes.map((item, i) => {
            const isDisabled = item.value === "Dine In";
            const isCurbside = item.value === "Curbside";
            // In Store ordering must be hidden
            if (item.id === 5) return null;

            return (
                <Col key={`${i}`} xs={12} sm={6} md={colWidth} style={{ margin: 'auto', padding: '6px 16px' }}>
                    <Button className='orderTypeBtns' style={Object.assign({}, orderBtnStyle, isArabic && isCurbside ? { paddingRight: '0px' } : null)} color="outline-success" onClick={() => this.loadOrderTypeData(item)} disabled={isDisabled}>
                        <Col xs={4} sm={4} style={isArabic ? buttonsTextContainerRTL : buttonsTextContainer}>
                            {this.renderButtonIcon(item)}
                        </Col>
                        <Col xs={8} sm={8} style={isArabic ? buttonsTextContainerRTL : buttonsTextContainer}>
                            <span style={isArabic ? buttonTextStyleRTL : buttonTextStyle}>
                                {i18n.t(`screens:selectOrderTypeScreen.${item.value.toLowerCase().replace(' ', '')}`)}
                            </span>
                        </Col>
                    </Button>
                </Col>
            );
        });
    }

    loadOrderTypeData(item) {
        this.props.loadOrderTypeData(item);
    }

    renderButtonIcon(orderType) {
        const { type, primaryColor, secondaryColor } = this.props.customerThemes.selectedTheme;
        const isArabic = this.props.language === ARABIC;

        if (orderType.id == DELIVERY_ID) {
            return (
                <DeliverySVG style={isArabic ? { transform: 'scaleX(-1)' } : null} width={42} height={29} fill={type === 'DARK' ? secondaryColor : primaryColor} />
            );
        }

        if (orderType.id == COLLECTION_ID) {
            return (
                <ShoppingCartSVG width={30} height={29} fill={type === 'DARK' ? secondaryColor : primaryColor} />
            );
        }

        if (orderType.id == CURBSIDE_ID) {
            return (
                <CurbsideSVG width={60} height={30} viewBoxNewValues={'0 0 170.7 97.2'} fill={type === 'DARK' ? secondaryColor : primaryColor} />
            );
        }
    }

    renderModalFooter() {
        return (
            <Fragment />
        );
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { mainTextColor, primaryFontColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.loginTextStyle.color = mainTextColor;
        customizedStyles.profileNameStyle.color = primaryFontColor;
        customizedStyles.profileNameStyleRTL.color = primaryFontColor;
        styles = customizedStyles;

        return styles;
    }

    renderTransparentBackgroundLoaderContainer() {
        const { loadingContainerTransparent, loadingIndicator } = styles;
        const { primaryColor } = this.props.customerThemes.selectedTheme;

        if (this.props.loading.transparentLoader) {
            return (
                <div style={loadingContainerTransparent}>
                    <div style={loadingIndicator} className='text-center loadingIndicator'>
                        <GridLoader color={primaryColor} size={10} />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const starOrderStyles = isMobile ? { overflow: 'auto', height: window.innerHeight } : {}

        return (
            <div className="start-order" style={starOrderStyles}>
                <ModalStep
                    onToggle={this.props.toggle}
                    // renderHeader={this.renderModalHeader()}
                    renderBody={this.renderModalBody()}
                    renderFooter={this.renderModalFooter()}
                    styles={styles}
                    customerThemes={this.props.customerThemes}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        orderTypes: state.orderTypes.orderTypes,
        logoImagePath: state.orderTypes.logoImagePath,
        brand: state.brand,
        customer: state.customer,
        customerThemes: state.customerThemes,
        loading: state.loading,
        language: state.storeLocalStorage.language,
        selectedLanguage: state.storeLocalStorage.selectedLanguage,
        isDefaultLanguage: state.storeLocalStorage.isDefaultLanguage
    };
};

export default connect(mapStateToProps, { getOrderTypes, loadOrderTypeData, loadCustomerFromLocalStorage, saveCustomerDeliveryAddress, saveStartOrderTime, setApplicationType, changeLanguage })(SelectOrderTypeScreen);

let styles = {
    modalBody: {
        textAlign: 'center',
        padding: 0,
        minHeight: '480px',
        backgroundColor: '#ffffff'
    },
    modalStyle: {
        border: 'none',
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    profileNameStyleRTL: {
        marginRight: -10,
        verticalAlign: 'middle',
        fontSize: 20
    },
    centredItem: {
        margin: '0 auto',
        fontSize: 20,
        textAlign: 'center',
    },
    loginTextStyle: {
        textAlign: 'center',
        color: '#9a9a9a',
        fontSize: 14,
        marginTop: 8
    },
    marginStyle: {
        marginBottom: 30,
        marginTop: 30
    },
    orderBtnStyle: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
        // justifyContent: 'flex-start'
        // padding: '0px 0px 0px 20px',
        // position: 'relative'
    },
    fontStyle: {
        fontSize: 22,
        textAlign: 'center',
    },
    fullWidth: {
        width: '100%'
    },
    headerColumn: {
        paddingLeft: 0,
        width: 72,
        marginTop: 1,
        visibility: 'hidden'
    },
    buttonsContainer: {
        margin: 'auto',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1
    },
    login: {
        fontSize: 22,
        marginLeft: 12,
        marginRight: 12
    },
    heading: {
        fontSize: 22,
        margin: 'auto',
        textAlign: 'center'
    },
    imageStyle: {
        marginRight: 16
    },
    imageStyleRTL: {
        marginLeft: 16
    },
    loginImageIcon: {
        marginRight: 12
    },
    loginImageIconRTL: {
        marginLeft: 12
    },
    orderImageStyle: {
        paddingRight: 14
    },
    orderImageStyleRTL: {
        paddingLeft: 14
    },
    loadingIndicator: {
        width: '72px',
        height: '72px',
    },
    loadingContainerTransparent: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        verticalAlign: 'middle',
        zIndex: 100000000
    }
}
